.validate-content {
	margin-top: 50px;
}

ion-item.contact-number ion-input {
	--padding-bottom: 7px;
	font-size: 14px;
}

.web .route-order-summary .ion-padding .absolute-content {
	padding: var(--ion-padding);
	margin-top: 0;
}

.web .route-order-summary .ion-padding .absolute-content .scrollable-y ion-grid.order-content-wrapper,
.web .route-order-summary .ion-padding .absolute-content .scrollable-y .validate-content

/*,
.web .route-order-summary .ion-padding .absolute-content .flex-min*/
	{
	padding: 0 var(--ion-padding);
}

.web .route-order-summary .checkout {
	margin-bottom: 20px;
}

.web .route-order-summary .ion-padding ion-button:last-child {
	margin-bottom: 0;
}

.web .route-order-summary .ion-padding ion-button.add-items-btn,
.web .route-order-summary .ion-padding ion-button.redeem-points-btn {
	margin: 10px 0 !important;
}

.web .route-order-summary .ion-padding ion-button.redeem-points-btn.disabled,
.web .route-order .no-padding ion-button.redeem-points-btn.disabled {
	--ion-color-dark: gray;
}

.allergen-modal>.modal-wrapper>.ion-page {
	min-width: 100%;
	overflow: auto;
}

ion-modal.modal-classic.allergen-modal {
	--height: 100%;
	--width: 100%;
	--border-radius: 0;
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-closer {
	font-size: 20px;
	z-index: 5;
}

.contact-number-label {
	padding-top: 3px;
}

.contact-number-btns {
	position: absolute;
	right: 2px;
	bottom: 2px;
	height: 38px;
	width: 38px;
}

.contact-number-btns ion-button {
	height: 100%;
	width: 100%;
	--border-radius: 50%;
}

.contact-number-icon {
	position: absolute;
	right: 0;
	bottom: 2px;
	font-size: 15px;
	cursor: pointer;
	border-radius: 50%;
	padding: 7px;
	transition: 0.2s ease-in-out;
	margin: auto;
	bottom: 0;
	top: 0;
}

.web .contact-number-icon {
	bottom: 4px;
}

.web .contact-number-icon:hover {
	background: rgba(0, 0, 0, 0.13);
}

ion-button.checkout-btn.greyed {
	/* --background: var(--ion-color-gray-tint); */
	/* --background: var(--ion-color-secondary); */
	color: var(--ion-color-gray-tint);
}

.order-summary-header-wrapper {
	display: flex;
	background-color: var(--okx-box-wrapper-background);
	/* border: 1px solid var(--ion-color-gray); */
	border-radius: var(--okx-box-radius);
}

.order-summary-header-wrapper * {
	color: var(--okx-box-wrapper-color);
}

.order-summary-header-wrapper>div {
	padding: 10px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	flex: 1;
}

.web .order-summary-header-wrapper>div {
	padding: 5px 15px;
}

.order-summary-header-wrapper>div:nth-of-type(2) {
	align-items: center;
	flex: unset;
	border-left: 1px solid var(--ion-color-gray);
}

.add-items-button-wrapper {
	position: absolute;
	top: -35px;
	right: 0;
}

.order-content-wrapper {
	position: relative;
}

ion-grid.order-content-wrapper,
ion-grid.order-content-wrapper ion-col {
	padding: 0 !important;
}

.summary-wrapper .add-items-btn {
	height: 30px;
	border-radius: 0;
}

.order-summary-additional-buttons-wrapper {
	border-radius: var(--okx-box-radius);
	overflow: hidden;
}

.order-summary-additional-buttons-wrapper>*:not(:last-child) {
	border-bottom: 1px solid var(--ion-color-gray);
}

.order-summary-additional-button {
	border-radius: var(--okx-box-radius);
	--border-color: transparent !important;
	background-color: var(--okx-box-wrapper-background);
	margin: 0 !important;
	--padding-start:10px;
	--padding-end:10px
}

.order-summary-additional-button * {
	color: var(--okx-box-wrapper-color);
}

.order-summary-additional-button>ion-text {
	flex: 1;
	text-align: left;
}

.order-summary-sidebar .flex-min {
	padding: 0;
}

.quantity-modal .incrementer-quantity-value {
	font-weight: 800 !important;
	font-size: var(--okx-medium-font-size) !important;
}


.discount-icon {
	margin-right: 10px;
}

.web .order-summary-sidebar .box-content,
.web .order-summary-sidebar .order-content-wrapper,
.web .route-order-summary .box-content,
.web .route-order-summary .order-content-wrapper {
	border-radius: var(--okx-box-radius);
	border: 1px solid var(--okx-textfield-border);
}

.order-header-title {
	margin-bottom: 10px;
}

.pay-buttons-wrapper {
	display: flex;
	flex-direction: column;
}

ion-modal.modal-classic.allergen-modal .absolute-content{
	padding-top: var(--safe-area-inset-top);
}

ion-modal.modal-classic.allergen-modal .modal-classic-closer{
	top: var(--safe-area-inset-top) !important;
	right: var(--ion-padding) !important;
}

.remove-apply-voucher{
	--width: 70% !important;
}