.loyalty-code-generator {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.loyalty-code-generator .subtitle {
	color: var(--ion-color-primary) !important;
	font-size: var(--okx-subtitle-font-size);
	text-align: start;
	line-height: 1.2;
}
.loyalty-code-generator .small-text {
	color: var(--okx-box-wrapper-color) !important;
	text-align: start;
	line-height: 1.2;
}
.loyalty-code-generator .subtitle{
	text-align: start !important;
}
.loyalty-code-generator .title {
	text-align: end;
	margin: 0 !important;
}
.loyalty-code-generator .title b:first-child {
	margin-right: 10px;
}
.loyalty-code-generator .title.success b {
	color: var(--okx-box-wrapper-color) !important;
}
.loyalty-code-generator .title.danger b {
	color: var(--ion-color-danger) !important;
}
.loyalty-code-generator .title.warning b {
	color: var(--ion-color-warning) !important;
}
