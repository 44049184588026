.validate-button .validate-status {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
  border-radius: 15px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  user-select: none;
}
.validate-button.valid .validate-status {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}

.validate-button {
  padding: 0;
}
.validate-button ion-col {
  padding: 0;
}
