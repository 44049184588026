/* 

.static.route-my-membership + ion-toolbar.dynamic-header {
	--ion-toolbar-background: var(--ion-color-secondary);
}

.web .static.route-my-membership + ion-toolbar.dynamic-header {
	--ion-toolbar-background: transparent;
}

.topbackground {
	background-color: var(--okx-dashboard-background);
	padding: var(--ion-padding) 0;
}

.rounded-bottom {
	border-bottom-left-radius: var(--okx-box-radius);
	border-bottom-right-radius: var(--okx-box-radius);
}

.web .rounded-bottom {
	border-radius: var(--okx-box-radius);
}

.web ion-content.route-my-membership {
	background-color: #fff;
}

.my-membership-content {
	padding: 20px;
}
ion-button.checkout-btn.greyed {
	--background: var(--ion-color-gray) !important;
	color: #fff;
}



.route-my-membership .title:first-child {
	margin-bottom: 0 !important;
}

.align-left {
	text-align: left;
}
.my-membership-header,
.my-membership-header .title,
.my-membership-header .normal-text {
	color: var(--okx-dashboard-color);
}
.my-membership-header {
	padding: var(--ion-padding);
}
.title.days-remaining {
	font-size: 40px;
}

.route-my-membership .subscription-list ion-note {
	color: var(--ion-color-secondary);
	font-size: 9px;
	font-family: var(--ion-font-family-bold);
}
.route-my-membership .subscription-list ion-note ion-label,
.route-my-membership .subscription-list ion-label {
	font-family: var(--ion-font-family-bold);
	color: var(--ion-color-primary);
}

.route-my-membership .ion-padding {
	padding: 0;
} */

.route-my-membership .title {
  line-height: 1.1;
}
.membership-card {
  background-color: var(--ion-color-gray) !important;
}

.membership-card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.membership-card > div > div:last-child {
  text-align: end;
}
.membership-card-content {
  margin: 0 15px;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
}
.membership-card {
  z-index: 2;
}
.web .membership-card-content {
  z-index: 1;
}
.membership-card-content > div ion-icon {
  margin-left: 5px;
}
.membership-card-content > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.membership-card-content > div.subscription-perks {
  display: unset;
}
.subscription-perks > div {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.subscription-perks > div:not(:last-of-type) {
  margin-bottom: 10px;
}
