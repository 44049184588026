:root {
  --clickup-initial-height: 50px;
  /* --clickup-open-height: 50%; */
}

.pill {
  display: inline-block;
  border: 1px solid var(--ion-color-primary-contrast) !important;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast) !important;
  font-size: 11px;
  padding: 2px 7px;
  text-transform: uppercase;
  border-radius: 15px;
  
}

.pill-action {
  cursor: pointer;
}
.pill-group{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}