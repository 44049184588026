.gift-voucher-resend-button .small-text {
  color: var(--ion-color-primary-contrast) !important;
}
.gift-voucher-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
  flex: 1;
  overflow: hidden;
}

.gift-voucher-history-list {
  padding: 0 !important;
  border: none !important;
}
.gift-voucher-history-list:has(ion-item) {
  border: 1px solid var(--okx-box-wrapper-border) !important;
}
