.loyalty-reward {
	height: 150px;
	width: 250px;
	background-size: cover;
	border-radius: var(--okx-box-radius);
	position: relative;
	overflow: hidden;
	padding: 15px;
	display: flex;
	align-items: flex-end;
  cursor: pointer;
}
.loyalty-reward:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 0;
  background-image: var(--okx-dashboard-card-background-gradient);
}
.loyalty-reward-locked *:not(ion-icon){
	z-index: -1;
}
.loyalty-reward-locked ion-icon{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 50px;
	height: 50px;
}
.loyalty-rewards-wrapper {
	display: grid;
	gap: 10px;
	grid-auto-flow: column;
	overflow-x: auto;
}
.loyalty-reward-points {
	position: absolute;
	top: 10px;
	right: 0;
	background-color: var(--ion-color-secondary);
	padding: 5px 10px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}
.loyalty-reward-points .strong-text {
	color: var(--ion-color-white);
}
.loyalty-reward-name * {
	color: var(--ion-color-white);
	line-height: 1.2;
}
.loyalty-reward-name {
	z-index: 2;
}
.unlock-rewards-modal .subtitle,
.unlock-rewards-modal .normal-text {
	text-align: start;
	display: block;
}