.dashboard-bars-wrapper {
  top: 80px;
  z-index: 5;
  width: 100%;
  padding-top: var(--ion-padding);
  background-color: var(--okx-dashboard-background);
}
.dashboard-bar-wrapper {
  width: 100%;
  padding: 0 var(--ion-padding) 20px;
  background-color: var(--okx-dashboard-background);
}
.dashboard-bar {
  width: 100%;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 20px;
  padding: 5px 20px;
}
.dashboard-bar ion-icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.dashboard-bars-wrapper .strong-text {
  color: var(--ion-color-primary);
}
